import React from 'react';
import Photo from '../../components/photo';
import { photos } from '../../data/photos';

const i18n = {
  description: (
    <p>
      DAY PHOTOS / NIGHT PHOTOS
    </p>
  ),
  photos,
  title_dark: "Dark",
  title_light: "Light"
};

export default (props) => <Photo i18n={i18n} {...props} />;
