export const photos = {
    light: [
        {
            alt: 'Photo',
            caption: 'Caption 1',
            link: '/images/dark.jpg'
        },
        {
            alt: 'Photo 2',
            caption: 'Caption 2',
            link: '/images/light.jpg'
        }
    ],
    dark: [
        {
            alt: 'Photo',
            caption: 'Caption 1',
            link: '/images/dark.jpg'
        },
        {
            alt: 'Photo 2',
            caption: 'Caption 2',
            link: '/images/light.jpg'
        }
    ]
}

export const photos_tr = {
    light: [
        {
            alt: 'Fotoğraf',
            caption: 'Açıklama 1',
            link: '/images/dark.jpg'
        },
        {
            alt: 'Fotoğraf 2',
            caption: 'Açıklama 2',
            link: '/images/light.jpg'
        }
    ],
    dark: [
        {
            alt: 'Fotoğraf',
            caption: 'Açıklama 1',
            link: '/images/dark.jpg'
        },
        {
            alt: 'Fotoğraf 2',
            caption: 'Açıklama 2',
            link: '/images/light.jpg'
        }
    ]
}