import React from "react"
import PropTypes from 'prop-types';
import Layout from "./layout"

const PhotoContainer = ({photos}) => {
    return (
        <div className="uk-child-width-1-2@m uk-child-width-expand@m" data-uk-grid data-uk-lightbox="animation: fade">
            {
                photos.map((photo) => 
                    <a key={photo.link} className="uk-inline" href={photo.link} data-caption={photo.caption}>
                        <img src={photo.link} alt={photo.alt} />
                    </a>
                )
            }
        </div>
    )
}

const Photo = ({location, i18n}, props) => {
    return (
        <Layout location={location}>
            
            <div className="uk-column-1-2@m uk-column-divider@m uk-text-center">
                <div className="uk-light uk-background-muted uk-padding">
                    <h3 className="uk-text-secondary">{i18n.title_light}</h3>
                    <p className="uk-text-secondary">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <a href="https://instagram.com/surqurer" className="uk-icon-button uk-button-secondary uk-margin-bottom" target="_blank" rel="noreferrer" aria-label="instagram-icon" data-uk-icon="icon: instagram; ratio: 1"> </a>
                    <PhotoContainer photos={i18n.photos.light} />
                </div>
                <div className="uk-dark uk-background-secondary uk-padding uk-margin-vertical@m">
                    <h3 className="uk-text-muted">{i18n.title_dark}</h3>
                    <p className="uk-text-muted">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <a href="https://instagram.com/surquwave" className="uk-icon-button uk-button-primary uk-margin-bottom" target="_blank" rel="noreferrer" aria-label="instagram-icon" data-uk-icon="icon: instagram; ratio: 1"> </a>
                    <PhotoContainer photos={i18n.photos.dark} />
                </div>
            </div>
        </Layout>
    );
};

export default Photo

Photo.propTypes = {
    location: PropTypes.object.isRequired,
};